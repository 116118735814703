import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import useAxios from "react-use-axios";
import Table from "./Table";
import CsvDownloader from "react-csv-downloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  overflow: auto;
`;

const Download = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
`;

const DownloadButton = styled(CsvDownloader)`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #ff4f79;
  box-shadow: 10px 10px 5px #aaaaaa;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
`;

const DownloadIcon = styled(FontAwesomeIcon)`
  align-items: center;
  height: 100%;
  color: white;
  font-size: 20px;
`;

export interface APIResponse {
  data: string;
}

interface RouteParams {
  id: string;
}

function Submissions() {
  let { id } = useParams<RouteParams>();

  console.log(id);

  const [res] = useAxios<APIResponse[]>(
    {
      url: "/formapi/get_form",
      method: "POST",
      data: JSON.stringify({ id: id }),
    },
    [id]
  );

  if (res.type === "loading") {
    return <div>Loading...</div>;
  }
  if (res.type === "error") {
    return <div>{"Error: " + res.data}</div>;
  }

  if (res.data !== null) {
    const data = res.data;
    console.log(data);

    const cleanData = data
      .map((d) => {
        if (d.data !== "") {
          const urlParams = new URLSearchParams(d.data);
          return Object.fromEntries(urlParams);
        }
      })
      .filter(isDefined);

    var uniqKeys = Array.from(
      new Set(
        cleanData
          .map((d) => {
            if (d !== undefined) {
              return Object.keys(d);
            }
          })
          .flat()
      )
    ).filter(isDefined);

    console.log(uniqKeys);
    console.log(cleanData);

    return (
      <Container>
        <Table data={cleanData} header={uniqKeys}></Table>
        <Download>
          <DownloadButton
            filename="form_submissions"
            extension=".csv"
            separator=";"
            wrapColumnChar='"'
            datas={cleanData}
            meta={false}
          >
            <DownloadIcon icon={faDownload}></DownloadIcon>
          </DownloadButton>
        </Download>
      </Container>
    );
  } else {
    return <div>Keine Daten</div>;
  }
}

function isDefined<T>(input: T | undefined | null): input is T {
  return typeof input !== "undefined" && input !== null;
}

export default Submissions;
