import React from "react";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import styled from "styled-components/macro";
import { bsrBlue } from "../bsr-defs";
import Submissions from "./components/Submissions";

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 200px minmax(0, 1fr);
  grid-template-rows: auto;

  min-height: calc(100vh - 105px);
`;

const SidebarContainer = styled.div`
  background: #39444d;
  padding-top: 15px;
`;

const SidebarLinks = styled.div``;

const Links = styled(NavLink)`
  color: #fff;
  font-size: 1.2rem;
  text-decoration-line: none;
  display: block;
  box-sizing: inherit;
  padding: 10px 0 10px 20px;
  border-left: 3px solid transparent;

  :hover {
    background: #272f37;
    border-left: 3px solid white;
  }
`;

const ContentContainer = styled.div``;

function Form() {
  const match = useRouteMatch();

  return (
    <MainContainer>
      <SidebarContainer>
        <SidebarLinks>
          <Links
            to={`${match.url}/1`}
            activeStyle={{
              background: "#272f37",
              borderLeft: "3px solid white",
            }}
          >
            150 J. Bestellung
          </Links>
        </SidebarLinks>
        <SidebarLinks>
          <Links
            to={`${match.url}/2`}
            activeStyle={{
              background: "#272f37",
              borderLeft: "3px solid white",
            }}
          >
            MZR
          </Links>
        </SidebarLinks>
        <SidebarLinks>
          <Links
            to={`${match.url}/3`}
            activeStyle={{
              background: "#272f37",
              borderLeft: "3px solid white",
            }}
          >
            Tennisplatz
          </Links>
        </SidebarLinks>
        <SidebarLinks>
          <Links
            to={`${match.url}/4`}
            activeStyle={{
              background: "#272f37",
              borderLeft: "3px solid white",
            }}
          >
            Weiterbildung
          </Links>
        </SidebarLinks>
        <SidebarLinks>
          <Links
            to={`${match.url}/5`}
            activeStyle={{
              background: "#272f37",
              borderLeft: "3px solid white",
            }}
          >
            Berufsbildner
          </Links>
        </SidebarLinks>
        <SidebarLinks>
          <Links
            to={`${match.url}/6`}
            activeStyle={{
              background: "#272f37",
              borderLeft: "3px solid white",
            }}
          >
            Schulfest
          </Links>
        </SidebarLinks>
      </SidebarContainer>
      <ContentContainer>
        <Switch>
          <Route path="/formulare/:id">
            <Submissions />
          </Route>
        </Switch>
      </ContentContainer>
    </MainContainer>
  );
}

export default Form;
