import {
  faBars,
  faSignOutAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components/macro";
import { signOut, useUser } from "../oidc";
import { ReactComponent as BrandBase } from "./zuerich-logo.svg";
import { ReactComponent as LogoBase } from "./zuerich-logo2.svg";
import { NavLink } from "react-router-dom";

const Brand = styled(BrandBase)`
  height: 75px;
  padding: 10px 0;
  padding-left: 20px;
  width: 70px;
  color: white;
  @media (min-width: 768px) {
    height: 85px;
    width: 80px;
  }
  @media (max-width: 1096px) {
    padding-left: 0px;
  }

  @media (max-width: 360px) {
    height: 44px;
    width: 39px;
  }
`;

const Logo = styled(LogoBase)`
  display: block;
  width: 20px;
  height: 20px;

  @media (min-width: 768px) {
    width: 27px;
    height: 27px;
  }

  @media (max-width: 360px) {
    height: 14px;
    width: 14px;
  }
`;

const Container = styled.nav`
  display: flex;
  flex-shrink: 0;
  background-color: #0076bd;
`;

const LogoContainer = styled.nav`
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    height: 100px;
  }
`;

const MainNavContainer = styled.div`
  margin-left: 10px;

  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const LoginNavContainer = styled.div`
  margin-left: 10px;

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 80px;

  @media (max-width: 1096px) {
    margin-right: 60px;
  }

  @media (max-width: 960px) {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;

const BrandTextContainer = styled.div`
  font-weight: 900;
  font-size: 24px;
  @media (min-width: 768px) {
    font-size: 38px;
    margin-top: 6px;
  }
`;

const LinkTitle = styled(NavLink)`
  color: white;
  text-decoration: none;
  padding-bottom: 3px;
`;

const LinkText = styled(NavLink)`
  color: white;
  text-decoration: none;
  border-bottom: 4px solid transparent;
  padding-bottom: 3px;
  :hover {
    border-bottom: 4px solid white;
  }
`;

const SubNavContainer = styled.div`
  z-index: 10;
  @media (min-width: 768px) {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease;
    padding: 0 10px;
    display: none;
    background: white;
  }
  @media (max-width: 768px) {
    padding-left: 20px;
  }
`;

const RootNavItem = styled.div`
  font-weight: 900;
  font-size: 28px;
  padding-bottom: 10px;
  padding-right: 20px;
  color: white;
  @media (max-width: 1056px) {
    font-size: 24px;
  }
`;

const RootNavContainer = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
    padding-left: 3px;
    display: flex;
    flex-flow: wrap;
    ${RootNavItem}:hover > ${SubNavContainer}, ${SubNavContainer}:hover {
      visibility: visible;
      opacity: 1;
      display: blocK;
      box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
        0 3px 3px 1px rgba(60, 64, 67, 0.16);
    }
  }
`;

const MobileContainer = styled.div`
  height: 100%;
  margin-right: 20px;
  margin-top: 32px;
`;

const MobileOpenContainer = styled.button`
  display: block;
  background: none;
  border: none;
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 394px) {
    margin-top: 5px;
  }
`;

function RenderRootNav(): JSX.Element {
  return (
    <>
      <RootNavItem>
        <LinkText
          activeStyle={{
            borderBottom: "4px solid white",
          }}
          activeClassName="active"
          to="/formulare"
        >
          Formulare
        </LinkText>
      </RootNavItem>
    </>
  );
}

const MiddleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LoginContainer = styled.div`
  box-sizing: border-box;
  display: block;
  align-items: center;
`;

const User = styled.div`
  display: block;
  font-size: 22px;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

const UserSubtext = styled.div`
  font-size: 14px;
  color: white;
`;

const UserIcon = styled(FontAwesomeIcon)`
  border-radius: 50%;
  font-size: 44px;
  overflow: hidden !important;
  box-sizing: border-box;
  min-width: 44px;
  background-color: white;
`;

const UserPicture = styled.img`
  border-radius: 50%;
  height: 44px;
  width: 44px;
`;

function UserProfilePicture(props: { url?: string }): JSX.Element {
  return props.url ? (
    <UserPicture alt="Profile" src={props.url} />
  ) : (
    <UserIcon icon={faUser} />
  );
}

const LogoutButton = styled.button`
  border-radius: 50px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  border: none;
  height: 44px;
  background: none;
`;

const UserName = styled.div`
  margin-left: 10px;
  font-size: 18px;
  color: white;
`;

export function BSRNavbar(props: { onOpenSidebar: () => void }): JSX.Element {
  const user = useUser();
  return (
    <Container>
      <LogoContainer>
        <NavLink to="/">
          <Brand />
        </NavLink>
        <NavLink to="/">
          <Logo />
        </NavLink>
      </LogoContainer>
      <MainNavContainer>
        <MiddleContainer>
          <BrandTextContainer>
            <LinkTitle to="/">Berufsschule Rüti</LinkTitle>
          </BrandTextContainer>
        </MiddleContainer>
        <RootNavContainer>
          <RenderRootNav />
        </RootNavContainer>
      </MainNavContainer>
      <LoginNavContainer>
        <LoginContainer>
          {user === null ? (
            <></>
          ) : (
            <User>
              <UserProfilePicture url={user.picture} />
              <UserName>
                <div>{user.name}</div>
                <UserSubtext>Angemeldet</UserSubtext>
              </UserName>
              <LogoutButton>
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  onClick={() => signOut()}
                />
              </LogoutButton>
            </User>
          )}
        </LoginContainer>
      </LoginNavContainer>
      <MobileContainer>
        <MobileOpenContainer onClick={props.onOpenSidebar}>
          <FontAwesomeIcon icon={faBars} size="2x" />
        </MobileOpenContainer>
      </MobileContainer>
    </Container>
  );
}

const SideNavbarContainer = styled.div`
  background-color: white;
  padding: 40px;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
`;

const SideNavbarLoginButton = styled.div`
  align-self: flex-start;
  margin-top: auto;
`;

export function BSRNavbarSide(): JSX.Element {
  const user = useUser();
  return (
    <SideNavbarContainer>
      <RenderRootNav />
      <SideNavbarLoginButton>
        {user === null ? <></> : <span>{user.name}</span>}
      </SideNavbarLoginButton>
    </SideNavbarContainer>
  );
}
