import React from "react";
import styled from "styled-components/macro";
import { FilterProps, useFilters, useTable } from "react-table";

const TableCustom = styled.table`
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;

  td,
  th {
    padding-left: 6px;
    text-align: left;
    border-collapse: collapse;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
  }
  thead tr {
    height: 60px;
    //background: #36304a;
    //border-bottom: 3px solid gray;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  thead th {
    padding: 0px;
  }

  tbody tr:last-child {
    border: 0;
  }
  tbody tr:nth-child(even) {
    background: rgba(0, 0, 0, 0.03);
  }
  tbody tr {
    color: #222;
    height: 35px;
  }
  tbody tr:hover {
    background-color: #dcdcdc;
  }
`;

const HeaderText = styled.div`
  padding: 5px 5px 5px 7px;
  box-shadow: -9px 0 white, 9px 0 white, 0 2px 15px 0 rgba(0, 0, 0, 0.15);
`;

const Filter = styled.div`
  padding: 5px;

  input,
  select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 5px 7px;
    border-radius: 3px;
    font-weight: 400;
    outline-width: 0;
  }
`;

function Table(props: {
  data: {
    [k: string]: string;
  }[];
  header: string[];
}) {
  const columns = React.useMemo(
    () =>
      props.header.map(d => {
        return { accessor: d, Header: d };
      }),
    [props.header]
  );
  const data = props.data;

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data, defaultColumn }, useFilters);

  return (
    <TableCustom {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                <HeaderText>{column.render("Header")}</HeaderText>
                <Filter>
                  {column.canFilter ? column.render("Filter") : null}
                </Filter>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </TableCustom>
  );
}

// DefaultColumnFilter is a dynamic filter that automatically switches between drop down or search input
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, id }
}: FilterProps<any>) {
  // gets uniq row values for the dropdown filter, but if there are more then 5 different values it will return false. This will cause a fallback to search filtering.
  const options = React.useMemo(() => {
    const options = new Set<any>();
    for (let row of preFilteredRows) {
      options.add(row.values[id]);
      if (options.size > 5) {
        return false;
      }
    }
    return Array.from(options).filter(isDefined);
  }, [id, preFilteredRows]);

  if (options === false) {
    return (
      <input
        value={filterValue || ""}
        onChange={e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Suche ...`}
      />
    );
  } else {
    return (
      <select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">Alle</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }
}

function isDefined<T>(input: T | undefined | null): input is T {
  return typeof input !== "undefined" && input !== null;
}

export default Table;
