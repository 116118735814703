import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  margin: 50px 0px 0px 50px;
  padding: 20px;
  border-radius: 30px;
  background: #e0e0e0;
  box-shadow: 15px 15px 30px #8f8f8f, -15px -15px 30px #ffffff;
`;

const LinkText = styled(NavLink)`
  text-decoration: none;
  border-bottom: 4px solid transparent;
  padding-bottom: 3px;
`;

function Home() {
  return (
    <FlexBox>
      <Container>
        {" "}
        <LinkText
          activeStyle={{
            borderBottom: "4px solid white",
          }}
          activeClassName="active"
          to="/formulare"
        >
          Formulare
        </LinkText>
      </Container>
    </FlexBox>
  );
}

export default Home;
