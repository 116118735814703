import React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";
import { BSRNavbar } from "./common/Navbar";
import Form from "./form/Form";
import Home from "./home/home";
import { ProcessResponse, signInAndRedirect, useUser } from "./oidc";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" name="Login">
          <Login />
        </Route>
        <Route exact path="/login/callback" name="Login Callback">
          <LoginCallback />
        </Route>
        <PrivateRoute exact path="/" name="Internal">
          <BSRNavbar onOpenSidebar={() => {}}></BSRNavbar>
          <Home />
        </PrivateRoute>
        <PrivateRoute path="/formulare" name="Formulare">
          <BSRNavbar onOpenSidebar={() => {}}></BSRNavbar>
          <Form />
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
}

export default App;

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute<T extends RouteProps = RouteProps>({
  children,
  ...rest
}: T) {
  const user = useUser();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user !== null ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
}

const LoginCallback = () => {
  ProcessResponse();
  return <></>;
};

const Login = () => {
  signInAndRedirect("/");
  return <></>;
};
