import { Base64 } from "js-base64";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { oidcSettings } from "./config";

interface OIDCState {
  lastLocation: string;
  nonce: string;
}

export async function signIn() {
  await signInAndRedirect(window.location.pathname);
}

export async function signInAndRedirect(pathname: string) {
  const stateToken = nanoid();
  const nonce = nanoid();
  const state: OIDCState = {
    lastLocation: pathname,
    nonce,
  };
  const oidcDiscoveryData = await (
    await fetch(oidcSettings.authority + "/.well-known/openid-configuration")
  ).json();

  window.sessionStorage.setItem(stateToken, JSON.stringify(state));
  const queryParams = new URLSearchParams({
    client_id: oidcSettings.client_id,
    redirect_uri: oidcSettings.redirect_uri,
    response_type: "id_token",
    scope: "openid",
    state: stateToken,
    nonce,
  });
  window.location.replace(
    oidcDiscoveryData.authorization_endpoint + "?" + queryParams
  );
}

export function signOut() {
  localStorage.removeItem("oidc-token");
  userEffectCallbacks.forEach((cb) => cb());
}

export function ProcessResponse() {
  const history = useHistory();
  const response = new URLSearchParams(window.location.hash.substr(1));
  const stateToken = response.get("state");
  if (stateToken === null) {
    console.log("No state token for OIDC callback");
    history.replace({ pathname: "/" });
    return;
  }
  const newIDToken = response.get("id_token");
  if (newIDToken === null) {
    console.log("No state token for OIDC callback");
    history.replace({ pathname: "/" });
    return;
  }
  const newIDClaim = parseJWT(newIDToken);
  if (newIDClaim === null) {
    console.log("Invalid ID token passed for OIDC callback");
    history.replace({ pathname: "/" });
    return;
  }
  const stateRaw = window.sessionStorage.getItem(stateToken);
  if (stateRaw === null) {
    console.log("No OIDC login with this state token registered");
    history.replace({ pathname: "/" });
    return;
  }
  const state = JSON.parse(stateRaw) as OIDCState;
  if (newIDClaim.nonce !== state.nonce) {
    console.log("Invalid OIDC nonce, probably a replay");
    history.replace({ pathname: "/" });
    return;
  }
  sessionStorage.removeItem(stateToken);
  localStorage.setItem("oidc-token", newIDToken);
  history.replace({ pathname: state.lastLocation });
  userEffectCallbacks.forEach((cb) => cb());
}

interface IDClaim {
  sub: string;
  iss: string;
  aud: string;
  exp: number;
  iat: number;
  amr: string[];
  nonce: string;
  name: string;
  picture?: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email?: string;
  email_verified?: boolean;
  updated_at: number;
  groups: string[];
}

function parseJWT(jwt: string): IDClaim | null {
  const jwtParts = jwt.split(".");
  if (jwtParts.length !== 3) {
    return null;
  }
  const [, contentRaw] = jwtParts;
  return JSON.parse(Base64.decode(contentRaw));
}

export function getUser(): IDClaim | null {
  const token = localStorage.getItem("oidc-token");
  if (token === null) {
    return null;
  }

  const tokenVal = parseJWT(token);
  if (tokenVal === null) {
    return null;
  }

  if (Math.floor(new Date().valueOf() / 1000) >= tokenVal.exp) {
    localStorage.removeItem("oidc-token");
    userEffectCallbacks.forEach((cb) => cb());
    return null;
  }
  return tokenVal;
}

let userEffectCallbacks: (() => void)[] = [];

export function useUser() {
  const [user, setUser] = useState(getUser());
  useEffect(() => {
    const callback = () => {
      setUser(getUser());
    };
    userEffectCallbacks.push(callback);
    return () => {
      userEffectCallbacks = userEffectCallbacks.filter((cb) => cb !== callback);
    };
  });
  return user;
}

export function getIDToken(): string | null {
  const token = localStorage.getItem("oidc-token");
  if (token === null) {
    return null;
  }
  const tokenVal = parseJWT(token);
  if (tokenVal === null) {
    return null;
  }
  if (Math.floor(new Date().valueOf() / 1000) >= tokenVal.exp) {
    return null;
  }
  return token;
}
